import React from 'react';
import Icon from './icon';
import { useTranslation } from 'react-i18next';

import './travelers-pricing.scss';

const TravelersPricing = () => {
  const { t } = useTranslation('TravelersPricing');

  return (
    <section>
      <h1 className="is-sr-only">Pricing details</h1>
      <div className="st-travelers-pricing">
        <div className="st-travelers-pricing__labels">
          <div className="st-travelers-pricing__label is-range">
            {t('label.amountSpentWithStamp')}
          </div>
          <div className="st-travelers-pricing__label is-fee">{t('label.appliedFee')}</div>
        </div>

        <div className="st-travelers-pricing__table">
          <div className="st-travelers-pricing__line">
            <div className="st-travelers-pricing__range">
              <div className="st-travelers-pricing__range-end">
                <div className="st-travelers-pricing__range-label">{t('label.from')}</div>
                <div className="st-travelers-pricing__range-value">€0</div>
              </div>
              <Icon name="arrow-right" size={1.6} className="st-travelers-pricing__arrow"></Icon>
              <div className="st-travelers-pricing__range-end is-last">
                <div className="st-travelers-pricing__range-label">{t('label.upTo')}</div>
                <div className="st-travelers-pricing__range-value">€150</div>
              </div>
            </div>
            <div className="st-travelers-pricing__fee">
              <div className="st-travelers-pricing__fee-amount is-free">{t('label.free')}</div>
            </div>
          </div>

          <div className="st-travelers-pricing__line">
            <div className="st-travelers-pricing__range">
              <div className="st-travelers-pricing__range-end">
                <div className="st-travelers-pricing__range-label">{t('label.from')}</div>
                <div className="st-travelers-pricing__range-value">€150</div>
              </div>
              <Icon name="arrow-right" size={1.6} className="st-travelers-pricing__arrow"></Icon>
              <div className="st-travelers-pricing__range-end is-last">
                <div className="st-travelers-pricing__range-label">{t('label.upTo')}</div>
                <div className="st-travelers-pricing__range-value">€300</div>
              </div>
            </div>
            <div className="st-travelers-pricing__fee">
              <div className="st-travelers-pricing__fee-amount">€3,99</div>
              <div className="st-travelers-pricing__fee-label">{t('label.oneTimeFee')}</div>
            </div>
          </div>

          <div className="st-travelers-pricing__line">
            <div className="st-travelers-pricing__range">
              <div className="st-travelers-pricing__range-end">
                <div className="st-travelers-pricing__range-label">{t('label.from')}</div>
                <div className="st-travelers-pricing__range-value">€300</div>
              </div>
              <Icon name="arrow-right" size={1.6} className="st-travelers-pricing__arrow"></Icon>
              <div className="st-travelers-pricing__range-end is-last">
                <div className="st-travelers-pricing__range-label">{t('label.upTo')}</div>
                <div className="st-travelers-pricing__range-value">€500</div>
              </div>
            </div>
            <div className="st-travelers-pricing__fee">
              <div className="st-travelers-pricing__fee-amount">€9,99</div>
              <div className="st-travelers-pricing__fee-label">{t('label.oneTimeFee')}</div>
            </div>
          </div>

          <div className="st-travelers-pricing__line">
            <div className="st-travelers-pricing__range">
              <div className="st-travelers-pricing__range-end">
                <div className="st-travelers-pricing__range-label">{t('label.from')}</div>
                <div className="st-travelers-pricing__range-value">€500</div>
              </div>
              <Icon name="arrow-right" size={1.6} className="st-travelers-pricing__arrow"></Icon>
              <div className="st-travelers-pricing__range-end is-last">
                <div className="st-travelers-pricing__range-label">{t('label.upTo')}</div>
                <div className="st-travelers-pricing__range-value">€2.000</div>
              </div>
            </div>
            <div className="st-travelers-pricing__fee">
              <div className="st-travelers-pricing__fee-amount">€19,99</div>
              <div className="st-travelers-pricing__fee-label">{t('label.oneTimeFee')}</div>
            </div>
          </div>

          <div className="st-travelers-pricing__line">
            <div className="st-travelers-pricing__range">
              <div className="st-travelers-pricing__range-end">
                <div className="st-travelers-pricing__range-label">{t('label.from')}</div>
                <div className="st-travelers-pricing__range-value">€2.000</div>
              </div>
              <Icon name="arrow-right" size={1.6} className="st-travelers-pricing__arrow"></Icon>
              <div className="st-travelers-pricing__range-end is-last">
                <div className="st-travelers-pricing__range-label">{t('label.upTo')}</div>
                <div className="st-travelers-pricing__range-value">€5.000</div>
              </div>
            </div>
            <div className="st-travelers-pricing__fee">
              <div className="st-travelers-pricing__fee-amount">€24,99</div>
              <div className="st-travelers-pricing__fee-label">{t('label.oneTimeFee')}</div>
            </div>
          </div>

          <div className="st-travelers-pricing__line">
            <div className="st-travelers-pricing__range">
              <div className="st-travelers-pricing__range-end">
                <div className="st-travelers-pricing__range-label">{t('label.over')}</div>
                <div className="st-travelers-pricing__range-value">€5.000</div>
              </div>
            </div>
            <div className="st-travelers-pricing__fee">
              <div className="st-travelers-pricing__fee-amount">€39,99</div>
              <div className="st-travelers-pricing__fee-label">{t('label.oneTimeFee')}</div>
            </div>
          </div>
        </div>

        <div className="st-travelers-pricing__subtitle">{t('label.vatIncluded')}</div>
      </div>
    </section>
  );
};

export default TravelersPricing;
