import React from 'react';
import { Trans } from 'react-i18next';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './travelers-testimonials.scss';

const testimonials = [
  {
    quote: 'testimonials.quote1',
    author: 'Han (China)',
  },
  {
    quote: `testimonials.quote2`,
    author: 'Michael (United States)',
  },
  {
    quote: 'testimonials.quote3',
    author: 'Disha (India)',
  },
  {
    quote: 'testimonials.quote4',
    author: 'Adriana (Brazil)',
  },
];

class TravelersTestimonials extends React.Component {
  sliderSettings = {
    arrows: false,
    dots: true,
    adaptiveHeight: false,
  };

  next = () => this.slider.slickNext();
  prev = () => this.slider.slickPrev();

  render() {
    return (
      <div className="box st-c-travelers-testimonials">
        <Slider
          {...this.sliderSettings}
          ref={c => (this.slider = c)}
          className="st-c-travelers-testimonials__slider"
        >
          {testimonials.map((testimonial, index) => (
            <blockquote key={index} className="st-c-travelers-testimonials__quote">
              <p>
                <Trans i18nKey={`TravelersPage:${testimonial.quote}`}>
                  <span className="u-underline-styled"></span>
                </Trans>
              </p>
              <footer className="st-c-travelers-testimonials__footer">
                <div className="st-c-travelers-testimonials__author">{testimonial.author}</div>
              </footer>
            </blockquote>
          ))}
        </Slider>

        <div className="st-c-travelers-testimonials__controls">
          <button onClick={this.prev}>&larr;</button>
          <button onClick={this.next}>&rarr;</button>
        </div>
      </div>
    );
  }
}

export default TravelersTestimonials;
