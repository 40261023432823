import React from 'react';

import { useTranslation, Trans } from 'react-i18next';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Hero from '../components/hero';
import LocalizedLink from '../components/localized-link';
import SavingsCalculator from '../components/savings-calculator';
import TravelersHowItWorks from '../components/travelers-how-it-works';
import AppDownloadButton from '../components/app-download-button';
import TravelersTestimonials from '../components/travelers-testimonials';
import BulletPoint from '../components/bullet-point';
import PressQuotes from '../components/press-quotes';
import TravelersPricing from '../components/travelers-pricing';
import TravelersSubscription from '../components/travelers-subscription';
import SmoothScroll from '../components/smooth-scroll';
import DutyFreeDistrictAnnouncement from '../components/duty-free-district-announcement';

import './travelers.scss';

const IndexPage = () => {
  const { t } = useTranslation('TravelersPage');

  return (
    <Layout className="st-p-travelers">
      <SEO title={t('pageTitle')} />
      <Hero>
        <h1>
          <Trans i18nKey="TravelersPage:hero.title">
            <span className="u-underline-styled"></span>
          </Trans>
        </h1>
        <p>{t('hero.subtitle')}</p>
        <div className="section no-padding-bottom">
          <div className="buttons">
            <SmoothScroll to="download" className="button is-primary is-medium">
              {t('Global:phrase.downloadApp')}
            </SmoothScroll>
            <SmoothScroll to="savings-calculator" className="button is-light is-medium">
              {t('phrase.howMuchCanISave')}
            </SmoothScroll>
          </div>
        </div>

        <div className="st-p-travelers__announcement">
          <DutyFreeDistrictAnnouncement></DutyFreeDistrictAnnouncement>
        </div>
      </Hero>

      <div
        id="savings-calculator"
        className="st-p-landing__section st-p-travelers__savings-calculator"
      >
        <div className="container">
          <div className="has-text-centered">
            <div className="st-p-landing__subtitle">{t('savings.subtitle')}</div>
            <h2 className="title is-1 is-spaced">{t('savings.title')}</h2>
            <p className="subtitle is-5">{t('savings.paragraph')}</p>
          </div>

          <div className="section columns is-centered">
            <div className="column is-three-quarters-desktop">
              <div className="st-p-travelers__calculator-container">
                <SavingsCalculator />
              </div>
            </div>
          </div>

          <div className="has-text-centered">
            <h3 className="title is-4">
              <Trans i18nKey="TravelersPage:phrase.downloadAndSaveItAll">
                <span className="u-underline-styled"></span>
              </Trans>
            </h3>
            <div className="st-p-travelers__download-buttons">
              <AppDownloadButton type="ios"></AppDownloadButton>
              <AppDownloadButton type="android"></AppDownloadButton>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section no-padding-top">
        <div className="container">
          <h2 className="st-p-landing__subtitle">{t('howItWorks.subtitle')}</h2>
          <p className="title is-1">{t('howItWorks.title')}</p>

          <div className="section">
            <TravelersHowItWorks />
          </div>

          <div className="section field is-grouped is-grouped-centered">
            <div className="control">
              <SmoothScroll to="download" className="button is-primary is-medium">
                {t('Global:phrase.downloadApp')}
              </SmoothScroll>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section st-p-travelers__testimonials">
        <div className="container">
          <div className="columns is-centered has-text-centered">
            <div className="column is-two-thirds">
              <h2 className="st-p-landing__subtitle">{t('testimonials.subtitle')}</h2>
              <p className="title is-1 has-text-white">{t('testimonials.title')}</p>
            </div>
          </div>

          <div className="columns is-centered">
            <div className="column is-four-fifths">
              <div className="section">
                <TravelersTestimonials />
              </div>
            </div>
          </div>

          <div className="section has-text-centered">
            <p className="title is-4">
              <Trans i18nKey="TravelersPage:phrase.joinCustomersUsingStamp">
                <span className="u-underline-styled"></span>
              </Trans>
            </p>
            <div className="st-p-travelers__download-buttons">
              <AppDownloadButton type="ios"></AppDownloadButton>
              <AppDownloadButton type="android"></AppDownloadButton>
            </div>
          </div>
        </div>
      </div>

      <aside className="st-p-landing__section st-p-travelers__countries-presence">
        <div className="container">
          <div className="columns">
            <div className="column is-two-thirds-desktop">
              <h2 className="st-p-landing__subtitle">{t('countriesPresence.subtitle')}</h2>
              <p className="title is-3 is-spaced">{t('countriesPresence.title')}</p>
              <p className="subtitle is-5">{t('countriesPresence.paragraph')}</p>
            </div>
          </div>
        </div>
      </aside>

      <div className="st-p-landing__section st-p-travelers__why-stamp">
        <div className="container">
          <h2 className="st-p-landing__subtitle">{t('whyStamp.subtitle')}</h2>
          <p className="title is-1 is-spaced">{t('whyStamp.title')}</p>

          <div className="section">
            <div className="columns">
              <div className="column is-half">
                <BulletPoint>
                  <div className="content">
                    <h4>{t('whyStamp.payWithoutVat.title')}</h4>
                    <p className="subtitle is-6">{t('whyStamp.payWithoutVat.description')}</p>
                  </div>
                </BulletPoint>
              </div>
              <div className="column is-half">
                <BulletPoint>
                  <div className="content">
                    <h4>{t('whyStamp.cutHassle.title')}</h4>
                    <p className="subtitle is-6">{t('whyStamp.cutHassle.description')}</p>
                  </div>
                </BulletPoint>
              </div>
            </div>
            <div className="columns">
              <div className="column is-half">
                <BulletPoint>
                  <div className="content">
                    <h4>{t('whyStamp.noHiddenFees.title')}</h4>
                    <p className="subtitle is-6">{t('whyStamp.noHiddenFees.description')}</p>
                  </div>
                </BulletPoint>
              </div>
              <div className="column is-half">
                <BulletPoint>
                  <div className="content">
                    <h4>{t('whyStamp.greatSupport.title')}</h4>
                    <p className="subtitle is-6">{t('whyStamp.greatSupport.description')}</p>
                  </div>
                </BulletPoint>
              </div>
            </div>
          </div>

          <div className="section field is-grouped is-grouped-centered">
            <div className="control">
              <SmoothScroll to="download" className="button is-primary is-medium">
                {t('Global:phrase.downloadApp')}
              </SmoothScroll>
            </div>
          </div>

          <div className="st-p-landing__section st-p-travelers__pricing">
            <div className="columns is-two-fifths-tablet is-half-desktop">
              <div className="column">
                <div className="section no-padding-top">
                  <h3 className="title is-3 is-spaced">{t('pricing.title')}</h3>

                  <Trans i18nKey="TravelersPage:pricing.paragraph">
                    <p className="subtitle is-5"></p>
                    <p className="subtitle is-5"></p>
                  </Trans>
                </div>
              </div>
              <div className="column is-three-fifths-tablet is-half-desktop">
                <TravelersPricing />
              </div>
            </div>
          </div>

          <div className="st-p-landing__section st-p-travelers__pricing">
            <div className="columns is-two-fifths-tablet is-half-desktop">
              <div className="column">
                <div className="section no-padding-top">
                  <h3 className="title is-3 is-spaced">{t('pricing.subscription.title')}</h3>

                  <Trans i18nKey="TravelersPage:pricing.subscription.pricing">
                    <p className="subtitle is-5"></p>
                    <p className="subtitle is-5"></p>
                  </Trans>

                  <a href="mailto:subscriptions@stampit.co" class="button is-primary is-medium">
                    {t('pricing.subscription.calltoaction')}
                  </a>
                </div>
              </div>
              <div className="column is-three-fifths-tablet is-half-desktop">
                <TravelersSubscription />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="st-p-landing__section st-p-travelers__press">
        <div className="container">
          <h2 className="st-p-landing__subtitle">{t('PressQuotes:title')}</h2>
          <PressQuotes></PressQuotes>
        </div>
      </div>

      <div id="download" className="st-p-landing__section st-p-travelers__final-cta">
        <div className="container">
          <h2 className="st-p-landing__subtitle">{t('Global:phrase.downloadApp')}</h2>
          <div className="columns is-vcentered is-gapless">
            <div className="column is-half">
              <p className="title is-1 is-spaced">{t('finalCta.title')}</p>
            </div>
            <div className="column is-half">
              <div className="section no-padding-bottom">
                <div className="st-p-travelers__download-buttons">
                  <AppDownloadButton type="ios"></AppDownloadButton>
                  <AppDownloadButton type="android"></AppDownloadButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <aside className="st-p-travelers__secondary-cta">
        <div className="container st-p-travelers__secondary-cta-text">
          <p>
            <Trans i18nKey="TravelersPage:phrase.businessAside">
              <LocalizedLink to="/business"></LocalizedLink>
            </Trans>
          </p>
        </div>
      </aside>
    </Layout>
  );
};
export default IndexPage;
